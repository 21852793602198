import clsx from 'clsx';
import {map} from 'lodash';
import Link from 'next/link';
import config from '@root/config';
import {firstValueFrom} from 'rxjs';
import IconBox from '@root/common/IconBox';
import Carousel from '@root/common/Carousel';
import {isValidArray, isValidObj} from '@root/libs/utils';
import UserService from '@root/services/user';
import OrderService from '@root/services/order';
import React, {useEffect, useRef, useState} from 'react';
import LocaleService from '@root/services/locale';
// import Product from '@root/common/ProductMiniature/Chkt';
import Product from '@root/common/ProductMiniature/Main';

import styles from './styles.module.scss';

function BuyAgain({data}) {
  const carouselRef = useRef(null);
  const {t} = LocaleService.useLocale();
  const [isUser, setIsUser] = useState(null);
  const [listData, setListData] = useState([]);

  const carouselProps = {
    items: 6,
    margin: 0,
    dots: false,
    rtl: LocaleService.isArabic,
    responsive: {
      0: {items: 2},
      768: {items: 3},
      990: {items: 4},
      1200: {items: 5},
    },
  };

  const onClickNext = () => {
    carouselRef.current.next();
  };

  const onClickPrev = () => {
    carouselRef.current.prev();
  };

  useEffect(() => {
    let subs = UserService.$user.subscribe(setIsUser);
    return () => subs.unsubscribe();
  }, []);

  useEffect(() => {
    if (!isValidObj(isUser)) return;
    firstValueFrom(
      OrderService.getFavouritItems(config.past_ordered_count),
    ).then(result => setListData(result?.data?.results));
  }, [isUser]);

  if (!isUser || !isValidArray(listData)) return;
  return (
    <div className={styles.buyagain}>
      <div className={styles.buyagain_head}>
        <div className={styles.buyagain_heading}>
          <h4 className={styles.buyagain_heading_title}>
            {data?.title?.[LocaleService.current]}
          </h4>
          <span className={styles.buyagain_heading_subtitle}>
            {data?.subtitle?.[LocaleService.current]}
          </span>
        </div>
        <Link href={'/favourites'} className={styles.buyagain_alllink}>
          {t('br_show_all')}
        </Link>
      </div>
      <div className={styles.buyagain_list}>
        <div className={styles.buyagain_scroll}>
          <a
            onClick={onClickPrev}
            className={clsx(
              styles.buyagain_nav,
              LocaleService.isArabic ? styles.right : styles.left,
            )}>
            <IconBox
              name={LocaleService.isArabic ? 'chevron-right' : 'chevron-left'}
            />
          </a>
          <Carousel
            id={'buyagain'}
            ref={carouselRef}
            attributes={carouselProps}>
            {map(listData, (item, index) => {
              let first = index === 0;
              return (
                <div
                  className={clsx(styles.buyagain_item, first && styles.first)}
                  key={item?.id}>
                  <Product data={item} />
                </div>
              );
            })}
          </Carousel>
          <a
            onClick={onClickNext}
            className={clsx(
              styles.buyagain_nav,
              LocaleService.isArabic ? styles.left : styles.right,
            )}>
            <IconBox
              name={LocaleService.isArabic ? 'chevron-left' : 'chevron-right'}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default BuyAgain;
