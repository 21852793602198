import clsx from 'clsx';
import React from 'react';
import {get} from 'lodash';

import styles from './styles.module.scss';

function Pricing({data}) {
  // const hasVip = hasIn(data, ['vip_price']);
  const price = get(data, ['price']);
  const isFree = get(data, ['is_free']);

  return (
    <div className={clsx(styles.pricing)}>
      <div className={styles.pricing_regular}>
        <span className={clsx(styles.pricing_original, styles.pricing_strike)}>
          {price}
        </span>

        <div className={styles.pricing_free}>
          <span
            className={clsx(
              styles.pricing_special,
              isFree && styles.pricing_free_text,
            )}>
            {get(data, ['special_price'])}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Pricing;
