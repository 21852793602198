import clsx from 'clsx';
import Link from 'next/link';
import Image from 'next/image';
import React, {useState} from 'react';
import {get, hasIn, isArray, isUndefined, keys, isNull} from 'lodash';
import Countdown from 'react-countdown';
import AddToCart from '@root/common/AddToCart';
import DeviceView from '@root/common/DeviceView';
import LocaleService from '@root/services/locale';
import {isValidObj} from '@root/libs/utils';
import styles from './styles.module.scss';
import Pricing from './components/Pricing';
import ConfigsMobile from './components/ConfigsMobile';
import ConfigsDesktop from './components/ConfigsDesktop';

const defaultConfig = data => {
  return get(data, ['child', 0], data);
};

function Miniature({data, resetAndUpdate}) {
  const {t} = LocaleService.useLocale();
  const [selected, setSelected] = useState(defaultConfig(data));
  const bEnabled = get(data, ['flash_status'], false);

  const hasConfigs = () => isArray(data.child) && data.child.length !== 0;
  const badges = () => get(selected, ['additional_data', 'labels'], []);
  const configs = () => get(data, ['child'], []);
  const cardLabel = () => get(data, ['flash_label'], '');

  const onSelectConfig = config => {
    setSelected(config);
  };

  const hasPercent = hasIn(data, ['percent_off']);
  const percentOff = get(data, ['percent_off']);

  const formatNum = num =>
    num.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});

  // Renderer callback with condition
  const renderer = ({days, hours, minutes, seconds, completed}) => {
    if (completed) {
      // Render a completed state
      return <span></span>;
    } else {
      // Render a countdown
      return (
        <div className={styles.countdown}>
          <div className={styles.countdown_frame}>
            <span
              className={clsx(
                styles.countdown_count,
                data?.flash_status
                  ? styles.countdown_red
                  : styles.countdown_green,
              )}>
              {formatNum(hours)} :
            </span>
            <span
              className={clsx(
                styles.countdown_count,
                data?.flash_status
                  ? styles.countdown_red
                  : styles.countdown_green,
              )}>
              {formatNum(minutes)} :
            </span>
            <span
              className={clsx(
                styles.countdown_count,
                data?.flash_status
                  ? styles.countdown_red
                  : styles.countdown_green,
              )}>
              {formatNum(seconds)}
            </span>
          </div>
        </div>
      );
    }
  };

  const renderLable = () => {
    return (
      <div className={styles.miniature_badges}>
        <div className={styles.miniature_label}>
          <span className={styles.miniature_label_text}>{cardLabel()}</span>
        </div>
      </div>
    );
  };

  const renderTimer = () => {
    if (data?.flash_item_timer <= 0) return <></>;

    return (
      <div className={styles.miniature_countBadges}>
        {data?.flash_item_timer && (
          <Countdown
            key={get(data, ['id'], '')}
            // date={Date.now() + 10000} //hardconded
            date={Date.now() + data?.flash_item_timer * 1000}
            onComplete={() => {
              setTimeout(() => {
                resetAndUpdate();
              }, 2000);
            }}
            renderer={renderer}
          />
        )}
      </div>
    );
  };

  const renderItemStatus = () => {
    if (data?.flash_status) {
      // live
      return isValidObj(data?.flash_item_timer) ? renderTimer() : null;
    } else {
      if (isValidObj(data?.flash_item_timer && data?.flash_item_timer > 0)) {
        // upcoming
        return renderTimer();
      } else {
        // only labels (expired/sold/comingSoon)
        return renderLable();
      }
    }
  };

  return (
    <div className={styles.miniature}>
      <div
        className={clsx(
          styles.miniature_thumb,
          !bEnabled && styles.miniature_thumb_disabled,
        )}>
        {hasPercent && (
          <span className={styles.miniature_percent}>{percentOff}</span>
        )}
        <Link
          href={{
            pathname: data?.url,
            query: hasConfigs() ? {config: selected.id} : null,
          }}>
          <div className={styles.miniature_image}>
            <Image
              width={420}
              height={420}
              alt="product"
              src={get(selected, ['image'])}
            />
          </div>
        </Link>
      </div>
      {bEnabled && (
        <div className={styles.miniature_addcart}>
          <AddToCart
            product={data}
            configOption={selected}
            from="flash_sale_item"
          />
        </div>
      )}

      <div className={styles.miniature_origin}>
        {get(selected, ['origin']) && (
          <span className={styles.miniature_origin_text}>
            {get(selected, ['origin'])}
          </span>
        )}
      </div>

      {renderItemStatus()}

      <div className={styles.miniature_sumary}>
        <div className={styles.miniature_name}>
          <span className={styles.miniature_name_text}>
            {get(selected, ['name'])}
          </span>
        </div>
        <div className={styles.miniature_price}>
          <Pricing data={selected} />
        </div>
        <div
          className={clsx(
            styles.miniature_configs,
            !bEnabled && styles.miniature_configs_disabled,
          )}>
          <DeviceView
            mobile={
              <ConfigsMobile
                product={selected}
                configs={configs()}
                onSelect={onSelectConfig}
              />
            }
            desktop={
              <ConfigsDesktop
                product={selected}
                configs={configs()}
                onSelect={onSelectConfig}
              />
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Miniature;
