import React from 'react';
import LogoLink from '@root/common/LogoLink';


import styles from './styles.module.scss';
import SearchBar from './components/SearchBar';
import LangSwitch from '@root/common/LangSwitch';

function Header() {
  return <div className={styles.headmob}>
    <div className={styles.headmob_logo}><LogoLink /></div>
    <div className={styles.headmob_search}><SearchBar /></div>
    <div className={styles.headmob_lang}><LangSwitch /></div>
  </div>;
}

export default Header;
