import React from 'react';
import Link from 'next/link';
import LocaleService from '@root/services/locale';
import IconBox from '@root/common/IconBox';

import styles from './styles.module.scss';

function SearchBar() {
  const { t } = LocaleService.useLocale();

  return (
    <div className={styles.searchbar}>
      <Link href="/search">
        <span className={styles.searchbar_link}>
          <span className={styles.searchbar_text}>{t('home_what_are_you_looking_for')}</span>
          <IconBox className={styles.searchbar_icon} name="magnify" />
        </span>
      </Link>
    </div>
  );
}

export default SearchBar;
