import Link from 'next/link';
import {map} from 'lodash';
import {useRouter} from 'next/router';
import React, {useEffect, useState} from 'react';
import LocaleService from '@root/services/locale';
import ProductMiniature from '@root/common/ProductMiniature/Main';
import TrackingService, {VIEW_PROMO_PRODUCTS} from '@root/services/tracking';

import styles from './styles.module.scss';

function ProductSlider({data}) {
  const router = useRouter();
  const {t} = LocaleService.useLocale();
  const [stateData, setStateData] = useState({});

  useEffect(() => {
    TrackingService.triggerEvent(VIEW_PROMO_PRODUCTS, data);
  }, []);

  return (
    <div className={styles.myitems}>
      <div className={styles.myitems_head}>
        <h2 className={styles.myitems_heading}>{data?.label}</h2>
        <span className={styles.myitems_headlink}>
          <Link href={`/${data?.url}`}>{t('br_show_all')}</Link>
        </span>
      </div>
      <div className={styles.myitems_list}>
        <div className={styles.myitems_scroll}>
          {map(data?.content, (product, index) => {
            product['label'] = data?.label;
            product['from'] = router.pathname;
            return (
              <div className={styles.myitems_item} key={index}>
                <ProductMiniature data={product} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ProductSlider;
