import React from 'react';
import {map} from 'lodash';
import Link from 'next/link';
import Image from 'next/image';

import styles from './styles.module.scss';

function SubMenu({parent, categories}) {
  return (
    <div className={styles.submenu}>
      <div className="br_wrapper">
        <ul className={styles.submenu_list}>
          {map(categories, category => {
            return (
              <li key={category?.id} className={styles.submenu_item}>
                <Link
                  href={{
                    pathname: `/${category?.request_path}`,
                    query: {parent: parent.id, child: category.id},
                  }}>
                  <span className={styles.submenu_link}>
                    <span className={styles.submenu_image}>
                      <Image
                        alt=""
                        width={214}
                        height={214}
                        src={category?.image}
                      />
                    </span>
                    <span className={styles.submenu_title}>
                      {category?.name}
                    </span>
                  </span>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default SubMenu;
