import clsx from 'clsx';
import Link from 'next/link';
import {filter, map} from 'lodash';
import React, {useRef} from 'react';
import IconBox from '@root/common/IconBox';
import Carousel from '@root/common/Carousel';
import LocaleService from '@root/services/locale';

import styles from './styles.module.scss';

function ImgSlider({data}) {
  let lang = LocaleService.current;
  const carouselRef = useRef(null);
  let sliderOpts = data?.slider;
  let filtered = filter(data?.list, item => item?.active);

  const carouselProps = {
    items: 2,
    margin: 0,
    dots: false,
    margin: 15,
    loop: sliderOpts?.loop,
    rtl: LocaleService.isArabic,
    autoplay: sliderOpts?.autoplay,
    autoplayTimeout: sliderOpts?.autoPlayInterval,
    responsive: {
      0: {items: 1},
      768: {items: 1},
      990: {items: 2},
      1200: {items: 2},
    },
  };

  const onClickNext = () => {
    carouselRef.current.next();
  };

  const onClickPrev = () => {
    carouselRef.current.prev();
  };

  return (
    <div className={styles.imgslider}>
      <a
        onClick={onClickPrev}
        className={clsx(
          styles.imgslider_nav,
          LocaleService.isArabic ? styles.right : styles.left,
        )}>
        <IconBox
          name={LocaleService.isArabic ? 'chevron-right' : 'chevron-left'}
        />
      </a>
      <Carousel id="imgslider" ref={carouselRef} attributes={carouselProps}>
        {map(filtered, (item, index) => {
          return (
            <Link
              key={index}
              href={`${item?.link}`}
              className={styles.imgslider_link}>
              <img src={item?.image?.[lang]} />
            </Link>
          );
        })}
      </Carousel>
      <a
        onClick={onClickNext}
        className={clsx(
          styles.imgslider_nav,
          LocaleService.isArabic ? styles.left : styles.right,
        )}>
        <IconBox
          name={LocaleService.isArabic ? 'chevron-left' : 'chevron-right'}
        />
      </a>
    </div>
  );
}

export default ImgSlider;
