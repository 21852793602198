import React from 'react';
import AuthService from '@root/services/auth';

import Locations from './Locations';
import Addresses from './Addresses';

function LocationPicker(props) {
  const isAuth = AuthService.useAuth();

  if (isAuth) {
    return <Addresses {...props} />;
  } else {
    return <Locations {...props} />;
  }
}

export default LocationPicker;
