import clsx from 'clsx';
import {map} from 'lodash';
import Link from 'next/link';
import slugify from 'slugify';
import {useRouter} from 'next/router';
import IconBox from '@root/common/IconBox';
import Carousel from '@root/common/Carousel';
import React, {useRef, useEffect} from 'react';
import LocaleService from '@root/services/locale';
import ProductMiniature from '@root/common/ProductMiniature/Main';
import TrackingService, {VIEW_PROMO_PRODUCTS} from '@root/services/tracking';

import styles from './styles.module.scss';

function ProductSlider({data}) {
  const router = useRouter();
  const carouselRef = useRef(null);
  const {t} = LocaleService.useLocale();
  const elementId = slugify(data.label || '', {
    replacement: '_',
    lower: true,
    strict: true,
  });

  const carouselProps = {
    items: 6,
    margin: 0,
    dots: false,
    rtl: LocaleService.isArabic,
    responsive: {
      0: {items: 2},
      768: {items: 3},
      990: {items: 4},
      1200: {items: 5},
    },
  };

  useEffect(() => {
    TrackingService.triggerEvent(VIEW_PROMO_PRODUCTS, data);
  }, []);

  const onClickNext = () => {
    carouselRef.current.next();
  };

  const onClickPrev = () => {
    carouselRef.current.prev();
  };

  return (
    <div className={styles.myitems}>
      <div className={styles.myitems_head}>
        <h2 className={styles.myitems_heading}>{data?.label}</h2>
        <span className={styles.myitems_headlink}>
          <Link href={`/${data?.url}`}>{t('br_show_all')}</Link>
        </span>
      </div>
      <div className={styles.myitems_list}>
        <a
          onClick={onClickPrev}
          className={clsx(
            styles.myitems_nav,
            LocaleService.isArabic ? styles.right : styles.left,
          )}>
          <IconBox
            name={LocaleService.isArabic ? 'chevron-right' : 'chevron-left'}
          />
        </a>
        <Carousel id={elementId} ref={carouselRef} attributes={carouselProps}>
          {map(data?.content, (product, index) => {
            product['label'] = data?.label;
            product['from'] = router.pathname;
            return (
              <div className={styles.myitems_item} key={index}>
                <ProductMiniature data={product} />
              </div>
            );
          })}
        </Carousel>
        <a
          onClick={onClickNext}
          className={clsx(
            styles.myitems_nav,
            LocaleService.isArabic ? styles.left : styles.right,
          )}>
          <IconBox
            name={LocaleService.isArabic ? 'chevron-left' : 'chevron-right'}
          />
        </a>
      </div>
    </div>
  );
}

export default ProductSlider;
