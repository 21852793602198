import clsx from 'clsx';
import {map} from 'lodash';
import Link from 'next/link';
import config from '@root/config';
import {firstValueFrom} from 'rxjs';
import UserService from '@root/services/user';
import {isValidArray, isValidObj} from '@root/libs/utils';
import OrderService from '@root/services/order';
import React, {useEffect, useState} from 'react';
import LocaleService from '@root/services/locale';
import Product from '@root/common/ProductMiniature/Chkt';

import styles from './styles.module.scss';

function BuyAgain({data}) {
  const {t} = LocaleService.useLocale();
  const [isUser, setIsUser] = useState(null);
  const [listData, setListData] = useState([]);

  useEffect(() => {
    let subs = UserService.$user.subscribe(setIsUser);
    return () => subs.unsubscribe();
  }, []);

  useEffect(() => {
    if (!isValidObj(isUser)) return;
    firstValueFrom(
      OrderService.getFavouritItems(config.past_ordered_count),
    ).then(result => setListData(result?.data?.results));
  }, [isUser]);

  if (!isUser || !isValidArray(listData)) return;
  return (
    <div className={styles.buyagain}>
      <div className={styles.buyagain_head}>
        <div className={styles.buyagain_heading}>
          <h4 className={styles.buyagain_heading_title}>
            {data?.title?.[LocaleService.current]}
          </h4>
          <span className={styles.buyagain_heading_subtitle}>
            {data?.subtitle?.[LocaleService.current]}
          </span>
        </div>
        <Link href={'/favourites'} className={styles.buyagain_alllink}>
          {t('br_show_all')}
        </Link>
      </div>
      <div className={styles.buyagain_list}>
        <div className={styles.buyagain_scroll}>
          {map(listData, (item, index) => {
            let first = index === 0;
            return (
              <div
                className={clsx(styles.buyagain_item, first && styles.first)}
                key={item?.id}>
                <Product product={item} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default BuyAgain;
