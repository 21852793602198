import React from "react";
import Link from "next/link";
import Image from "next/image";
import LocaleService from "@root/services/locale";
import IconBox from "@root/common/IconBox";

import styles from "./styles.module.scss";

function VipCard({ data }) {
  const { t } = LocaleService.useLocale();

  return (
    <Link href="rewards-dashboard">
      <span className={styles.vipview}>
        <div className={styles.vipview_top}>
          <div className={styles.vipview_imgbox}>
            <Image
              alt="barakat vip logo"
              src={require("@root/assets/images/rewards_logo.png")}
            />
          </div>
          <h3 className={styles.vipview_heading}>{data?.line1}</h3>
          <div className={styles.vipview_balance}>
            <span className={styles.vipview_balance_coins}>
              {t("home_rewards_card_balance", {
                currency: data?.currency,
                balance: data?.balance,
                points: data?.points,
              })}
            </span>
          </div>
          {data?.tier_remain_days ? (
            <div className={styles.vipview_info}>
              <span className={styles.vipview_info_value}>
                {data?.tier_remain_days}
              </span>
              <span className={styles.vipview_info_title}>{t('home_fresh_cash_days_left')}</span>
            </div>
          ) : (
            <IconBox className={styles.vipview_chev} name={LocaleService.isArabic ? 'chevron-left' : 'chevron-right'} />
          )}
        </div>
        {(data?.line2 || data?.line3) && (
          <>
            <span className={styles.vipview_hr}>&nbsp;</span>
            <div className={styles.vipview_bottom}>
              {data?.line2 && (
                <span className={styles.vipview_bottom_message}>
                  {data?.line2}
                </span>
              )}
              {data?.line3 && (
                <span className={styles.vipview_bottom_notice}>
                  {data?.line3}
                </span>
              )}
            </div>
          </>
        )}
      </span>
    </Link>
  );
}

export default VipCard;
