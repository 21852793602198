import clsx from 'clsx';
import LocaleService from "@root/services/locale";
import React, { forwardRef, useImperativeHandle, useState } from 'react';

import styles from './styles.module.scss';

function FSModal(props, ref) {
  const [showModal, setShowModal] = useState(false);

  useImperativeHandle(ref, () => ({
    show() {
      setShowModal(true);
    },
    hide() {
      setShowModal(false);
    },
  }));

  if(!showModal) return null;
  return (
    <div className={clsx(styles.fsmodal, showModal && styles.active)}>
      <div className={styles.fsmodal_content}>
        {showModal && props.children}
      </div>
    </div>
  )
}

export default forwardRef(FSModal)