import {isNull} from 'lodash';
import {firstValueFrom} from 'rxjs';
import AuthService from '@root/services/auth';
import React, {useEffect, useState} from 'react';
import LocaleService from '@root/services/locale';
import LoggerService from '@root/services/logger';
import RewardsService from '@root/services/rewards';

import styles from './styles.module.scss';
import VipCard from './components/VipCard';
import ProCard from './components/ProCard';
import { isValidString } from '@root/libs/utils';

function VipView() {
  const {t} = LocaleService.useLocale();
  const isAuth = AuthService.useAuth();
  const [rewardsData, setRewardsData] = useState(null);

  useEffect(() => {
    if (!isAuth) return;

    firstValueFrom(RewardsService.getCurrentTier({fresh: true}))
      .then(result => {
        let data = result?.data;
        /** fix for employees */
        if(!isValidString(data?.line1)) {
          data['line1'] = `${data?.current_tier_name} ${t('rewards_member')}`;
        }
        setRewardsData(data);
      })
      .catch(error => {
        LoggerService.logError('Unable to load rewards data', error);
      });
  }, [isAuth]);

  return (!isAuth || isNull(rewardsData)) ? null : (
    <div className={styles.rewards}>
      {rewardsData.group_id === 9 ? (
        <VipCard data={rewardsData} />
      ) : (
        <ProCard data={rewardsData} />
      )}
    </div>
  );
}

export default VipView;
