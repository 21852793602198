import clsx from 'clsx';
import {map} from 'lodash';
import Link from 'next/link';
import React, {useState} from 'react';
import {isValidObj} from '@root/libs/utils';
import HoverIntent from '@root/common/HoverIntent';

import styles from './styles.module.scss';
import SubMenu from './components/SubMenu';

function MegaMenu({categories}) {
  const [active, setActive] = useState(false);

  const onHover = isHover => {
    setActive(isHover);
  };

  return (
    <div className={clsx(styles.megamenu)}>
      <HoverIntent onHover={onHover} delay={200}>
        <div className={clsx(styles.megamenu_box, active && styles.show)}>
          <ul className={styles.megamenu_list}>
            {map(categories, category => {
              if (category?.request_path) {
                return (
                  <li key={category?.id} className={styles.megamenu_item}>
                    <Link href={category?.request_path}>
                      <span className={styles.megamenu_link}>
                        {category?.name}
                      </span>
                    </Link>
                    {isValidObj(category) && (
                      <div className={styles.megamenu_child}>
                        <SubMenu
                          parent={category}
                          categories={category?.child_categories}
                        />
                      </div>
                    )}
                  </li>
                );
              } else {
                return <></>;
              }
            })}
          </ul>
        </div>
      </HoverIntent>
    </div>
  );
}

export default MegaMenu;
