import Link from 'next/link';
import {filter, map} from 'lodash';
import React, {useRef} from 'react';
import Carousel from '@root/common/Carousel';
import LocaleService from '@root/services/locale';

import styles from './styles.module.scss';

function ImgSlider({data}) {
  let lang = LocaleService.current;
  const carouselRef = useRef(null);
  let sliderOpts = data?.slider;
  let filtered = filter(data?.list, item => item?.active);

  const carouselProps = {
    items: 1,
    margin: 0,
    dots: false,
    loop: sliderOpts?.loop,
    rtl: LocaleService.isArabic,
    autoplay: sliderOpts?.autoplay,
    autoplayTimeout: sliderOpts?.autoPlayInterval,
  };

  return (
    <div className={styles.imgslider}>
      <Carousel id="imgslider" ref={carouselRef} attributes={carouselProps}>
        {map(filtered, (item, index) => {
          return (
            <Link
              key={index}
              href={`${item?.link}`}
              className={styles.imgslider_link}>
              <img src={item?.image?.[lang]} />
            </Link>
          );
        })}
      </Carousel>
    </div>
  );
}

export default ImgSlider;
