import React from "react";
import Link from 'next/link';
import Image from "next/image";
import IconBox from "@root/common/IconBox";
import LocaleService from "@root/services/locale";

import styles from './styles.module.scss';

function ProCard({data}) {
  const { t } = LocaleService.useLocale();
  
  return (
    <Link href="rewards-dashboard">
      <span className={styles.vipview}>
        <div className={styles.vipview_top}>
          <div className={styles.vipview_imgbox}>
            <Image alt="barakat vip logo" src={require("@root/assets/images/rewards_logo.png")}/>
          </div>
          <h3 className={styles.vipview_heading}>{data?.line1}</h3>
          <div className={styles.vipview_balance}>
            <span className={styles.vipview_balance_coins}>{t('home_rewards_card_balance', {
              currency: data?.currency,
              balance: data?.balance,
              points: data?.points
            })}</span>
          </div>
          {data?.remaining_orders ? <div className={styles.vipview_info}>
            <span className={styles.vipview_info_value}>{data?.placed_orders}/{data?.order_needs_to_complete}</span>
            <span className={styles.vipview_info_title}>{t('account_rewards_orders')}</span>
          </div> : <IconBox className={styles.vipview_chev} name="chevron-right" />}
        </div>
        {(data?.line2 || data?.line3) && <>
          <span className={styles.vipview_hr}>&nbsp;</span>
          <div className={styles.vipview_bottom}>
            {data?.line2 && <span className={styles.vipview_bottom_message}>{data?.line2}</span>}
            {data?.line3 && <span className={styles.vipview_bottom_notice}>{data?.line3}</span>}
          </div>
        </>}
      </span>
    </Link>
  );
}

export default ProCard;
