import clsx from 'clsx';
import Image from 'next/image';
import React, {useState} from 'react';
import {get, isNull, hasIn} from 'lodash';
import IconBox from '@root/common/IconBox';
import AddToCart from '@root/common/AddToCart';
import DLocationService from '@root/services/deliveryLocation';

import styles from './styles.module.scss';
import Configs from './components/Configs';

const defaultConfig = data => {
  return get(data, ['child', 0], data);
};

function Product({product}) {
  const [selected, setSelected] = useState(defaultConfig(product));
  const {locData} = DLocationService.useLocationData();
  const price = item => get(item, ['price']);
  const percentOff = item => get(item, ['percent_off']);
  const specialPrice = item => get(item, ['special_price']);
  const hasSpecial = item =>
    hasIn(item, ['special_price']) && !isNull(item.special_price);

  const configs = product => {
    return get(product, ['child'], []);
  };

  const onSelectConfig = config => {
    setSelected(config);
  };

  const showExpress = () => {
    return (
      locData?.regions?.express_delivery_available && product?.express_delivery
    );
  };

  return (
    <div className={styles.product}>
      <div className={styles.product_thumb}>
        <Image alt="" width={60} height={60} src={product?.image} />
      </div>
      <div className={styles.product_cart}>
        <AddToCart product={product} configOption={selected} />
      </div>
      <div className={styles.product_info}>
        <span className={styles.product_origin}>{product?.origin}</span>
        <span className={styles.product_title}>{product?.name}</span>
        <div className={styles.product_price}>
          <div className={styles.product_price_box}>
            {hasSpecial(product) && (
              <span className={styles.product_price_new}>
                {specialPrice(product)}
              </span>
            )}
            <span
              className={clsx(
                styles.product_price_old,
                hasSpecial(product) && styles.strike,
              )}>
              {price(product)}
            </span>
          </div>
          {hasSpecial(product) && (
            <div className={styles.product_price_percent}>
              <span className={styles.product_price_percent_value}>
                {percentOff(product)}
              </span>
            </div>
          )}
        </div>
      </div>
      <div className={styles.product_configs}>
        <Configs
          product={selected}
          configs={configs(product)}
          onSelect={onSelectConfig}
        />
      </div>
      {showExpress() && (
        <div className={styles.product_exp} style={{fontSize: 10}}>
          <IconBox
            name={'truck-delivery'}
            className={styles.product_exp_icon}
          />
        </div>
      )}
    </div>
  );
}

export default Product;
