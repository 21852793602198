import {map, find} from 'lodash';
// import BButton from '@root/common/BButton';
import UserService from '@root/services/user';
// import FormRadio from '@root/common/FormRadio';
import React, {useEffect, useState} from 'react';
import LocaleService from '@root/services/locale';
// import ExpressDelivery from '@root/common/ExpressDelivery';
import DLocationService from '@root/services/deliveryLocation';

import styles from './styles.module.scss';
import AddnSelect from '@root/common/AddnSelect';
import {toString} from 'lodash';

function Addresses({onDone}) {
  const {t} = LocaleService.useLocale();
  const [process, setProcess] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [selected, setSelected] = useState(null);
  const [addnew, setAddNew] = useState(false);
  const {locData, locRegion, locCity} = DLocationService.useLocationData();

  useEffect(() => {
    if (!locData || !addresses) return;

    // let city = locCity?.key;
    // let region = locRegion?.key;
    let storedLocData = DLocationService.storedLocation();
    let found = find(addresses, address => {
      return toString(address?.id) === toString(storedLocData?.addrId);
    });
    setSelected(found);
  }, [addresses, locData]);

  const addNewMode = () => {
    return addnew || addresses.length === 0;
  };

  useEffect(() => {
    setAddresses(UserService.addresses);
  }, [UserService.addresses]);

  const onClickAddress = address => {
    setSelected(address);
  };

  const processAddr = () => {};

  const onSelectAddress = addresss => {
    setSelected(addresss);
    setProcess(true);
    DLocationService.fetchLocations({
      addrId: addresss?.id,
      cityId: parseInt(addresss?.city_id),
      regionId: addresss?.region_id,
    })
      .then(onDone)
      .finally(() => setProcess(false));
  };

  return (
    <AddnSelect
      loading={false}
      onBack={onDone}
      current={selected}
      addresses={addresses}
      onAddress={onSelectAddress}
    />
  );
  // return (
  //   <div className={styles.addresses}>
  //     <div className={styles.addresses_head}>
  //       <h4 className={styles.addresses_heading}>{t('select_location')}</h4>
  //       {/* {addresses.length > 0 && (
  //         <a
  //           onClick={() => setAddNew(!addnew)}
  //           className={styles.addresses_headlink}>
  //           {addnew ? 'Cancel' : 'Add Address'}
  //         </a>
  //       )} */}
  //     </div>
  //     {/* {addNewMode() ? (
  //       <div className={styles.addresses_addnew}>
  //         <Address data={{}} onDone={onDone} />
  //       </div>
  //     ) : ( */}
  //     <div className={styles.addresses_list}>
  //       {map(addresses, (address, index) => {
  //         let isSelected = address?.id === selected?.id;
  //         // let isLast = addresses.length === index + 1;
  //         return (
  //           <div key={index}>
  //             <a
  //               onClick={() => onClickAddress(address)}
  //               className={styles.address}>
  //               <div className={styles.address_radio}>
  //                 <FormRadio selected={isSelected} />
  //               </div>
  //               <div className={styles.address_head}>
  //                 <span className={styles.address_name}>
  //                   {`${address?.city}, ${address?.region?.region}`}
  //                 </span>
  //                 {address?.express_delivery_available && (
  //                   <span className={styles.address_bubble}>
  //                     <ExpressDelivery />
  //                   </span>
  //                 )}
  //               </div>

  //               <span className={styles.address_detail}>
  //                 {`${address.street.join(', ')}, ${address?.city}, ${
  //                   address?.region?.region
  //                 }`}
  //               </span>
  //             </a>
  //             <span className={styles.address_hr}></span>
  //           </div>
  //         );
  //       })}
  //     </div>
  //     {/* )} */}

  //     {!addNewMode() && (
  //       <div className={styles.addresses_action}>
  //         <BButton
  //           loading={process}
  //           disabled={!selected}
  //           label={t('br_done')}
  //           onPress={processAddr}
  //           className={styles.locpicker_button}
  //         />
  //       </div>
  //     )}
  //   </div>
  // );
}

export default Addresses;
