import React from 'react';
import config from '@root/config';
import Page from '@root/common/Page';
import Home from '@root/modules/home';
import BaseService from '@root/services/base';
import ServerProps from '@root/libs/serverprops';
import LocaleService from '@root/services/locale';

function home(props) {
  return (
    <Page>
      <Home pageData={props} />
    </Page>
  );
}

export const getServerSideProps = ServerProps(async context => {
  LocaleService.current = context.locale;
  let city_id = context.req.cookies[config?.locationKey];
  let categories = await BaseService.getCategories(context.locale);
  let blocks = await BaseService.getPromoBlocks(
    context.device.device,
    context.locale,
    city_id,
  );
  let banners = await BaseService.getHeroBanners(
    context.device.device,
    context.locale,
  );

  return {
    props: {
      blocksData: blocks,
      bannersData: banners,
      catData: categories,
      locale: context.locale,
      ...context.device,
      ...context.nextI18,
    },
  };
});

export default home;
