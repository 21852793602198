import clsx from 'clsx';
import {map} from 'lodash';
import {get} from 'lodash';
import {useRouter} from 'next/router';
import Countdown from 'react-countdown';
import React, {useEffect, useState, useRef} from 'react';

import IconBox from '@root/common/IconBox';
import Spinner from '@root/common/Spinner';
import Carousel from '@root/common/Carousel';
import BaseService from '@root/services/base';
import {isValidArray, isValidObj} from '@root/libs/utils';
import DeviceView from '@root/common/DeviceView';
import LocaleService from '@root/services/locale';
import EventsService, {FALSH_SALES_ADD_TO_CART} from '@root/services/events';
import styles from './styles.module.scss';
import Miniature from './Miniature';

function FlashSale({data}) {
  const router = useRouter();
  const [blockData, setBlockData] = useState({});
  const [loading, setLoading] = useState(false);
  const carouselRef = useRef(null);
  const carouselProps = {
    items: 6,
    margin: 0,
    dots: false,
    rtl: LocaleService.isArabic,
    responsive: {
      0: {items: 2},
      768: {items: 3},
      990: {items: 4},
      1200: {items: 5},
    },
  };
  const onClickNext = () => {
    carouselRef.current.next();
  };

  const onClickPrev = () => {
    carouselRef.current.prev();
  };

  // const showBlock = () => isValidArray(blockData?.results);
  const formatNum = num =>
    num.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false});

  // Renderer callback with condition
  const renderer = ({days, hours, minutes, seconds, completed}) => {
    if (completed) {
      // Render a completed state
      return <span></span>;
    } else {
      // Render a countdown
      return (
        <div className={styles.countdown}>
          {/* days */}
          {days !== 0 && (
            <div className={styles.countdown_frame}>
              <span className={styles.countdown_count}>{formatNum(days)}</span>
              <span className={styles.countdown_symbol}>D</span>
            </div>
          )}
          {/* hours */}
          <div className={styles.countdown_frame}>
            <span className={styles.countdown_count}>{formatNum(hours)}</span>
            {days !== 0 && <span className={styles.countdown_symbol}>H</span>}
          </div>

          {/* mins */}
          <div className={styles.countdown_frame}>
            <span className={styles.countdown_count}>{formatNum(minutes)}</span>
            {days !== 0 && <span className={styles.countdown_symbol}>M</span>}
          </div>

          {/* secs */}
          <div className={styles.countdown_frame}>
            <span className={styles.countdown_count}>{formatNum(seconds)}</span>
            {days !== 0 && <span className={styles.countdown_symbol}>S</span>}
          </div>
        </div>
      );
    }
  };

  const blockLabel = () => {
    return (
      <div className={styles.myitems_head}>
        <h2 className={styles.myitems_heading}>
          {blockData?.flash_title_label}
        </h2>
        {blockData?.timer_start && (
          <Countdown
            key={get(blockData, ['results', '0', 'id'])}
            date={Date.now() + blockData?.timer_start * 1000}
            // date={Date.now() + 10000} //hardconded
            // date={Date.now() + 987654000} //hardconded
            onComplete={() => resetAndUpdate()}
            renderer={renderer}
          />
        )}
      </div>
    );
  };

  const resetAndUpdate = () => {
    if (!isValidObj(blockData)) setLoading(true);
    // setBlockData({});
    fetchProducts(data?.link);
  };

  useEffect(() => {
    const sub = EventsService.$event(FALSH_SALES_ADD_TO_CART).subscribe(() => {
      fetchProducts(data?.link);
    });
    fetchProducts(data?.link);
    return () => sub.unsubscribe();
  }, []);

  const fetchProducts = cat_id => {
    BaseService.getProducts(cat_id, 'flash_sale')
      .then(result => {
        setBlockData(get(result, ['data'], null));
      })
      .catch(error => {
        setBlockData({});
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const RenderDesktop = () => {
    return loading ? (
      <div className={styles.countdown_temp}>
        <Spinner size={16} />
      </div>
    ) : isValidArray(get(blockData, ['results'], [])) ? (
      <div className={styles.myitems}>
        {blockLabel()}
        <div className={styles.myitemsD_list}>
          <a
            onClick={onClickPrev}
            className={clsx(
              styles.myitemsD_nav,
              LocaleService.isArabic ? styles.right : styles.left,
            )}>
            <IconBox
              name={LocaleService.isArabic ? 'chevron-right' : 'chevron-left'}
            />
          </a>
          <Carousel
            id="flashSaleId"
            ref={carouselRef}
            attributes={carouselProps}>
            {map(blockData?.results, (product, index) => {
              product['label'] = data?.label;
              product['from'] = router.pathname;
              return (
                <div className={styles.myitemsD_item} key={index}>
                  <Miniature data={product} resetAndUpdate={resetAndUpdate} />
                </div>
              );
            })}
          </Carousel>
          <a
            onClick={onClickNext}
            className={clsx(
              styles.myitemsD_nav,
              LocaleService.isArabic ? styles.left : styles.right,
            )}>
            <IconBox
              name={LocaleService.isArabic ? 'chevron-left' : 'chevron-right'}
            />
          </a>
        </div>
      </div>
    ) : null;
  };

  const RenderMobile = () => {
    return loading ? (
      <div className={styles.countdown_temp}>
        <Spinner size={16} />
      </div>
    ) : isValidArray(get(blockData, ['results'], [])) ? (
      <div className={styles.myitems}>
        {blockLabel()}
        <div className={styles.myitems_list}>
          <div className={styles.myitems_scroll}>
            {map(blockData?.results, (product, index) => {
              product['label'] = data?.label;
              product['from'] = router.pathname;
              return (
                <div className={styles.myitems_item} key={index}>
                  <Miniature data={product} resetAndUpdate={resetAndUpdate} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    ) : null;
  };

  return <DeviceView mobile={<RenderMobile />} desktop={<RenderDesktop />} />;
}

export default FlashSale;
