import React from 'react';
import Image from 'next/image';
import UserService from '@root/services/user';
import LocaleService from '@root/services/locale';
import ExpressDelivery from '@root/common/ExpressDelivery';
import {get, join, isFunction, isUndefined, isNull, toString} from 'lodash';

import styles from './styles.module.scss';
import Actions from './components/Actions';
import CheckIcon from './components/CheckIcon';
import MapDetails from './components/MapDetails';

function AddressMiniature({address, selected, onEdit, onDelete, onSelect}) {
  const {t} = LocaleService.useLocale();

  const isDefault = () => {
    return get(address, ['default_shipping'], false);
  };

  const isExpress = () => {
    return get(address, ['express_delivery_available'], false);
  }

  const name = () => {
    let firstname = get(address, ['firstname'], '');
    let lastname = get(address, ['lastname'], '');
    return `${firstname} ${lastname}`;
  };

  const street = () => {
    let region = get(address, ['city'], '');
    let street = get(address, ['street'], []);
    let city = get(address, ['region', 'region'], '');
    return `${join(street, ', ')}, ${region}, ${city}`;
  };

  const phone = () => {
    return get(address, ['telephone'], '');
  };

  const isSelected = () => {
    if (isUndefined(selected) || isNull(selected)) return false;
    return toString(address.id) === toString(selected.id);
  };

  const onCliclSelect = address => {
    if (isFunction(onSelect)) onSelect(address);
  };

  return (
    <div onClick={() => onCliclSelect(address)} className={styles.miniature}>
      <div className={styles.miniature_box}>
        <div className={styles.miniature_tags}>
          {isDefault() && (
            <span className={styles.miniature_default}>{t('account_address_default')}</span>
          )}
          {isExpress() && <ExpressDelivery icon="truck-delivery" />}
        </div>
        <div className={styles.miniature_thumb}>
          <span className={styles.miniature_image}>
            <Image
              alt=""
              src={require('@root/assets/images/citymap.jpg')}
            />
          </span>
        </div>
        <div className={styles.miniature_content}>
          <div className={styles.miniature_icon}>
            <CheckIcon
              selectable={isFunction(onSelect)}
              selected={isSelected()}
            />
          </div>
          <div className={styles.miniature_summary}>
            <span className={styles.miniature_name}>{name()}</span>
            <span className={styles.miniature_address}>{street()}</span>
          </div>
          <div className={styles.miniature_info}>
            <div className={styles.miniature_info_item}>
              <span className={styles.miniature_info_title}>{t('br_phone')}</span>
              <span className={styles.miniature_info_value}>{phone()}</span>
            </div>
            <div className={styles.miniature_info_item}>
              <span className={styles.miniature_info_title}>{t('br_email')}</span>
              <span className={styles.miniature_info_value}>
                {UserService.email}
              </span>
            </div>
          </div>
          <div className={styles.miniature_actions}>
            <Actions
              default={isDefault()}
              onEdit={!isFunction(onEdit) ? null : () => onEdit(address)}
              onDelete={!isFunction(onDelete) ? null : () => onDelete(address)}
            />
          </div>
        </div>
      </div>
      {/* TODO: map detail not coming address */}
      <MapDetails address={address} />
    </div>
  );
}

export default AddressMiniature;
