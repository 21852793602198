import React from 'react';
import Link from 'next/link';
import {chunk, filter, map} from 'lodash';
import LocaleService from '@root/services/locale';

import styles from './styles.module.scss';

function Brands({data}) {
  const lang = LocaleService.current;
  const filtered = filter(data?.list, item => item?.active);
  let chunked = chunk(filtered, 2);

  return (
    <div className={styles.brands}>
      <div className={styles.brands_head}>
        <h4 className={styles.brands_heading}>{data?.heading[lang]}</h4>
      </div>
      <div className={styles.brands_scroll}>
        <div className={styles.brands_container}>
          {map(chunked, (item, index) => {
            let first = item[0];
            let second = item[1];

            return (
              <div key={index} className={styles.brand_column}>
                <Link href={`${first?.link}`}>
                  <div className={styles.brand_link}>
                    <img
                      alt=""
                      src={first?.image?.[lang]}
                      className={styles.brand_image}
                    />
                  </div>
                </Link>
                <Link href={`${second?.link}`}>
                  <div className={styles.brand_link}>
                    <img
                      alt=""
                      src={second?.image?.[lang]}
                      className={styles.brand_image}
                    />
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Brands;
