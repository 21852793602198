import clsx from "clsx";
import React from "react";
import IconBox from "../IconBox";
import { isFunction } from "lodash";
import LocaleService from "@root/services/locale";

import styles from './styles.module.scss'

function FormRadio({ label, selected, onChange, className }) {
  const { t } = LocaleService.useLocale();
  
  const onClickInput = () => {
    isFunction(onChange) && onChange(!selected);
  };

  return (
    <a onClick={onClickInput} className={clsx(styles.formradio, className)}>
      <IconBox
        name={selected ? "radiobox-marked" : "radiobox-blank"}
        className={styles.formradio_icon}
      />
      <span className={styles.formradio_title}>{label}</span>
    </a>
  );
}

export default FormRadio;
