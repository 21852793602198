import {get, map} from 'lodash';
import {firstValueFrom} from 'rxjs';
import LoggerService from '@root/services/logger';
import React, {useState, useEffect} from 'react';
import NoticeService from '@root/services/notice';
import {Carousel} from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import styles from './styles.module.scss';

function NoticeBoard({screen}) {
  const [notices, setNotices] = useState([]);
  
  useEffect(() => {
    firstValueFrom(NoticeService.fetchData(screen))
      .then(result => {
        let values = get(result, ['data', 'notices', 'values'], []);
        setNotices(values);
      })
      .catch(error => LoggerService.logError('Notice data failed', error));
  }, []);

  if (notices.length === 0) return null;
  else
    return (
      <Carousel
        autoPlay
        infiniteLoop
        axis="vertical"
        interval={5000}
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        showIndicators={false}>
        {map(notices, (item, index) => {
          return (
            <div key={index} className={styles.notice}>
              <span className={styles.notice_text}>{item}</span>
            </div>
          );
        })}
      </Carousel>
    );
}
export default NoticeBoard;
