import React from "react";
import Link from "next/link";
import Image from "next/image";
import { map, startsWith, includes } from "lodash";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import styles from "./styles.module.scss";

function Hero({ banners }) {

  const linkUrl = (link) => {
    if(includes(link, 'http')) return link;
    else if(startsWith(link, '/')) return link;
    else return `/${link}`;
  }
  
  return (
    <div className={styles.hero}>
      <Carousel
        rtl={true}
        centerMode
        infiniteLoop
        showIndicators
        interval={5000}
        autoPlay={true}
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        centerSlidePercentage={100}
      >
        {map(banners, (banner) => {
          return (
            <Link href={linkUrl(banner?.url)} key={banner.id}>
              <span className={styles.hero_link}>
                <Image
                  priority
                  width={600}
                  height={300}
                  alt={banner.name}
                  src={banner?.image_url}
                />
              </span>
            </Link>
          );
        })}
      </Carousel>
    </div>
  );
}

export default Hero;
