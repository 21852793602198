import React from 'react';
import {get, map} from 'lodash';
import NoSSR from '@root/common/NoSSR';
import {isValidArray} from '@root/libs/utils';
import LocaleService from '@root/services/locale';
import NoticeBoard from '@root/common/NoticeBoard';
import Illustration from '@root/common/Illustration';
import Layout from '@root/common/Layouts/Mobile';

import Hero from './components/Hero';
import Header from './components/Header';
import styles from './styles.module.scss';
import CatGrid from './components/CatGrid';
import Rewards from '../components/Rewards';
import QuickLinks from '../components/QuickLinks';
import BannerBlock from './components/BannerBlock';
import NextDelivery from '../components/NextDelivery';
import ProductSlider from './components/ProductSlider';
import FlashSale from '../components/FlashSale';
import DeliveryOpts from './components/DeliveryOpts';
import BuyAgain from './components/BuyAgain';
import Brands from './components/Brands';
import ImgSlider from './components/ImgSlider';

function HomeMobile({screenData}) {
  const {t} = LocaleService.useLocale();
  const bannersData = get(screenData, ['bannersData', 'data'], []);
  const catData = get(screenData, ['catData', 'data', 'results'], []);
  const blocksData = get(screenData, ['blocksData', 'data', 'results'], []);

  return (
    <Layout header={<Header />}>
      <div className={styles.home}>
        {!isValidArray(blocksData) ? (
          <div className={styles.error}>
            <Illustration
              button={t('br_retry')}
              title={t('br_error_something_went_wrong')}
              onPress={() => console.log('Pressed Retry')}
              message={t('br_error_something_wrong_plz_try_again')}
              image={require('@root/assets/images/general_error.png')}
            />
          </div>
        ) : (
          <div className={styles.home_content}>
            {map(blocksData, (block, index) => {
              switch (block.type) {
                case 'notice':
                  return (
                    <div key={index} className={styles.home_notice}>
                      <NoticeBoard screen="home" />
                    </div>
                  );
                case 'deliveryopts':
                  return (
                    <div key={index} className={styles.home_quicklinks}>
                      <NoSSR>
                        <DeliveryOpts data={block} />
                      </NoSSR>
                    </div>
                  );
                case 'myItems':
                  return (
                    <div key={index} className={styles.home_quicklinks}>
                      <NoSSR>
                        <BuyAgain data={block} />
                      </NoSSR>
                    </div>
                  );
                case 'quicklinks':
                  return (
                    <div key={index} className={styles.home_quicklinks}>
                      <NoSSR>
                        <QuickLinks />
                      </NoSSR>
                    </div>
                  );
                case 'nextdelivery':
                  return (
                    <div key={index} className={styles.nextdelivery}>
                      <NextDelivery />
                    </div>
                  );
                case 'hero':
                  if (!isValidArray(bannersData)) return null;
                  return (
                    <div key={index} className={styles.home_hero}>
                      <Hero banners={bannersData} />
                    </div>
                  );
                case 'flash':
                  return (
                    <div key={index} className={styles.home_products}>
                      <FlashSale data={block} />
                    </div>
                  );
                case 'catgrid':
                  if (!isValidArray(catData)) return null;
                  return (
                    <div key={index} className={styles.home_categories}>
                      <CatGrid categories={catData} />
                    </div>
                  );
                case 'freshcash':
                  return (
                    <div key={index} className={styles.home_vip}>
                      <NoSSR>
                        <Rewards />
                      </NoSSR>
                    </div>
                  );
                case 'brands':
                  return (
                    <div key={index} className={styles.home_banner}>
                      <Brands data={block} />
                    </div>
                  );
                case 'banners':
                  return (
                    <div key={index} className={styles.home_banner}>
                      <BannerBlock data={block} />
                    </div>
                  );
                case 'imgslider':
                  return (
                    <div key={index} className={styles.home_banner}>
                      <ImgSlider data={block} />
                    </div>
                  );
                case 'products':
                  return (
                    <div key={index} className={styles.home_products}>
                      <ProductSlider data={block} />
                    </div>
                  );
                default:
                  break;
              }
            })}
          </div>
        )}
      </div>
    </Layout>
  );
}

export default HomeMobile;
