import {get} from 'lodash';
import DeviceView from '@root/common/DeviceView';

import HomeDesktop from './Desktop';
import HomeMobile from './Mobile';

const Home = ({pageData}) => {
  const deviceType = get(pageData, ['device'], 'desktop');

  return (
    <DeviceView
      predicted={deviceType}
      mobile={<HomeMobile screenData={pageData} />}
      desktop={<HomeDesktop screenData={pageData} />}
    />
  );
};

export default Home;
