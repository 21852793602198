import config from '@root/config';
import LocaleService from './locale';
import RequestService from './request';

class Base {
  constructor() {
    if (!this.instance) this.instance = this;
    return this.instance;
  }

  getNewCategories(id) {
    return RequestService.get(
      `${config.base_path}_svc/api/es/v2/list_category`,
      {
        params: {
          category_id: id,
          language: LocaleService.current,
          shop: 'uae',
          source: 'msite',
          area: 'list',
        },
      },
    );
  }

  async getCategories(language = LocaleService.current) {
    return RequestService.get(`${config.base_path}_svc/api/es/v2/categories`, {
      params: {language, shop: 'uae'},
    });
  }

  getHeroBanners(device, language = LocaleService.current) {
    return RequestService.get(
      `${config.base_path}${language}/rest/V1/customapi/banners`,
      {
        params: {type: device},
      },
    );
  }

  getScreenBlocks(screen) {
    return RequestService.$get(`${config.base_path}_svc/api/md/v1/screen/`, {
      params: {language: LocaleService.current, screen: screen},
    });
  }

  getCartBlocks() {
    return RequestService.$get(`${config.base_path}_svc/api/md/v1/screen/`, {
      params: {language: LocaleService.current, screen: 'cart'},
    });
  }

  getPromoBlocks(device, language = LocaleService.current, city_id) {
    return RequestService.get(
      `${config.base_path}_svc/api/es/v2/home/promo-blocks/`,
      {params: {language, city_id, shop: 'uae', type: device}},
    );
  }

  getCmsBlocks(id, device, language = LocaleService.current) {
    return RequestService.get(`${config.base_path}rest/V1/cmsBlock/${id}`, {
      params: {language, shop: 'uae', type: device},
    });
  }

  /**
   * Fetching Products for a category type
   */
  getProducts = (catId, type) => {
    return RequestService.get(`${config.base_path}_svc/api/es/v1/home/`, {
      params: {
        type: type,
        shop: 'uae',
        category_id: catId,
        language: LocaleService.current,
      },
    });
  };
}
const BaseService = new Base();
export default BaseService;
