import React from 'react';
import Image from 'next/image';
import AuthService from '@root/services/auth';
import LocaleService from '@root/services/locale';

import styles from './styles.module.scss';
import Link from 'next/link';

function QuickLinks() {
  const {t} = LocaleService.useLocale();
  const isAuth = AuthService.useAuth();

  if(!isAuth) return null;
  return (
    <div className={styles.quicklinks}>
      <div className={styles.quicklinks_item}>
        <Link href='/orders'>
          <span className={styles.quicklinks_link} style={{backgroundColor: '#2BD566'}}>
            <span className={styles.quicklinks_title}>{t('br_myorders')}</span>
            <span className={styles.quicklinks_message}>
              <Image alt="" width={50} height={50} src={require('@root/assets/images/myorders.svg')} />
            </span>
          </span>
        </Link>
      </div>
      <div className={styles.quicklinks_item}>
        <Link href='/favourites'>
          <span className={styles.quicklinks_link} style={{backgroundColor: '#F8485E'}}>
            <span className={styles.quicklinks_title}>{t('home_buyagain')}</span>
            <span className={styles.quicklinks_message}>
              <Image alt="" width={50} height={50} src={require('@root/assets/images/buyagain.svg')} />
            </span>
          </span>
        </Link>
      </div>
      <div className={styles.quicklinks_item}>
        <Link href='/rewards-dashboard#reffer'>
          <span className={styles.quicklinks_link} style={{backgroundColor: '#FF8F1C'}}>
            <span className={styles.quicklinks_title}>{t('br_referfriend')}</span>
            <span className={styles.quicklinks_message}>
              <Image alt="" width={50} height={50} src={require('@root/assets/images/refferfriend.svg')} />
            </span>
          </span>
        </Link>
      </div>
    </div>
  );
}

export default QuickLinks;
