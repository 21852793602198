import {isFunction, isNull} from 'lodash';
import React, {useEffect, useRef} from 'react';

function HoverIntent({onHover, delay = 1000, ...rest}) {
  const rootRef = useRef(null);
  const valueRef = useRef(false);

  useEffect(() => {
    if (isNull(rootRef)) return;
    let timout = null;
    let element = rootRef.current;

    const hoverInHandle = () => {
      timout = setTimeout(() => {
        valueRef.current = true;
        isFunction(onHover) && onHover(true);
      }, delay);
    };

    const hoverOutHandle = () => {
      clearTimeout(timout);
      if(valueRef.current) {
        valueRef.current = false;
        isFunction(onHover) && onHover(false);
      }
    };

    element.addEventListener('mouseenter', hoverInHandle);
    element.addEventListener('mouseleave', hoverOutHandle);

    return () => {
      element.removeEventListener('mouseenter', hoverInHandle);
      element.removeEventListener('mouseleave', hoverOutHandle);
    };
  }, [rootRef]);

  return <div ref={rootRef}>{rest.children}</div>;
}

export default HoverIntent;
