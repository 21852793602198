import axios from 'axios';
import config from '@root/config';
import TokenService from './token';
import LocaleService from './locale';
import RequestService from './request';

class Order {
  constructor() {
    if (!this.instance) this.instance = this;
  }

  orderRatings(id, data) {
    let tosend = {
      comments: data.comments,
      order_experience_review: data.delivery,
      product_quality_review: data.product,
    };
    return RequestService.$authPost(
      `${config.base_path}${LocaleService.current}/rest/V1/last-order/experience/${id}`,
      {body: tosend},
    );
  }

  orderInvoice(url) {
    return axios({
      method: 'get',
      responseType: 'arrayBuffer',
      url: `${config.base_path}${LocaleService.current}/${url}`,
      headers: {Authorization: `Bearer ${TokenService.token}`}
    });
  }

  getOrders(page = 1) {
    return RequestService.$authGet(
      `${config.base_path}${LocaleService.current}/rest/V1/customapi/customer/order/list?currentPage=${page}`,
    );
  }

  orderAgain(id) {
    return RequestService.$authPost(
      `${config.base_path}rest/V1/repeatorder/${id}`,
    );
  }

  orderDetail(id) {
    return RequestService.$authGet(
      `${config.base_path}${LocaleService.current}/rest/V1/orders/me/${id}`,
    );
  }

  newOrderDetail(id) {
    return RequestService.$authGet(
      `${config.base_path}${LocaleService.current}/rest/V2/orders/me/${id}`,
    );
  }

  updateDeliveryTimeSlot(orderId, date, slotId) {
    let tosend = {orderId: orderId, date: date, slotId: slotId};
    return RequestService.$authPost(
      `${config.base_path}${LocaleService.current}/rest/V1/customapi/mobile/timeslot/setslot`,
      {body: tosend},
    );
  }

  getTimeslotsForOrder(orderid) {
    let tosend = {orderId: orderid};
    return RequestService.$authGet(
      `${config.base_path}${LocaleService.current}/rest/V1/customapi/mobile/timeslot/list/`,
      {params: tosend},
    );
  }

  getFavouritItems(limit = null) {
    return RequestService.$authGet(
      `${config.base_path}rest/V1/customer/order/past-ordered`,
      {
        params: {limit, language: LocaleService.current},
      },
    );
  }

  pastOrdersFromCat(categoryId, limit = 5) {
    return RequestService.$authGet(
      `${config.base_path}${LocaleService.current}/rest/V1/customer/order/orderbycategoryid/${categoryId}`,
      {params: {limit, language: LocaleService.current},}
    )
  }
}
const OrderService = new Order();
export default OrderService;
