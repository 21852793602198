import {isNull} from 'lodash';
import IconBox from '@root/common/IconBox';
import FSModal from '@root/common/FSModal';
import {filter, firstValueFrom} from 'rxjs';
import {isValidObj} from '@root/libs/utils';
import PopModal from '@root/common/PopModal';
import UserService from '@root/services/user';
import React, {useEffect, useRef} from 'react';
import TokenService from '@root/services/token';
import LocaleService from '@root/services/locale';
import DeviceView from '@root/common/DeviceView';
import AddressService from '@root/services/address';
import DLocationService from '@root/services/deliveryLocation';
import EventsService, {LOCATION_CHANGE} from '@root/services/events';

import styles from './styles.module.scss';
import LocationPicker from './components/LocationPicker';

let tempValue = null;
function NextDelivery() {
  const modalRef = useRef(null);
  const {t} = LocaleService.useLocale();
  const {locData, locRegion, locCity} = DLocationService.useLocationData();

  useEffect(() => {
    if (!tempValue) return;
    if (tempValue !== locCity?.key) {
      tempValue = null;
      EventsService.trigger({type: LOCATION_CHANGE});
    }
  }, [locCity]);

  useEffect(() => {
    let clocation = DLocationService.storedLocation();
    let tokenSub = TokenService.$token.subscribe(token => {
      if (isNull(token)) {
        DLocationService.fetchLocations({
          cityId: clocation?.city?.key,
          regionId: clocation?.region?.key,
        });
        setTimeout(() => {
          if (!isValidObj(clocation)) modalRef.current.show();
        }, 1000); // ask user for location
      } else {
        firstValueFrom(
          UserService.$user.pipe(filter(value => value !== null)),
        ).then(() => {
          let address = AddressService.pickAddress(
            UserService.addresses,
            clocation?.city?.key,
            clocation?.region?.key,
          );

          if (!address) {
            DLocationService.fetchLocations({
              cityId: clocation?.city?.key,
              regionId: clocation?.region?.key,
            });
            setTimeout(() => {
              if (!isValidObj(clocation)) modalRef.current.show();
            }, 1000); // ask user for location
          }

          DLocationService.fetchLocations({
            addrId: address?.id,
            regionId: address?.region_id,
            cityId: parseInt(address?.city_id),
          });
        });
      }
    });

    return () => tokenSub.unsubscribe();
  }, []);

  const onClickToggle = () => {
    tempValue = locCity?.key; // temp city value
    modalRef.current.show();
  };

  const onDoneAction = () => {
    modalRef.current.hide();
  };

  return (
    <>
      <div onClick={onClickToggle} className={styles.next}>
        <a className={styles.next_toggle}>
          <div className={styles.next_location}>
            <span className={styles.next_location_text}>
              {locCity?.label} - {locRegion?.label}
            </span>
            <IconBox
              className={styles.next_location_chev}
              name="chevron-down"
            />
          </div>
          <div className={styles.next_timeslot}>
            <div className={styles.next_timeslot_day}>
              <IconBox
                className={styles.next_timeslot_icon}
                name="truck-fast-outline"
              />
              <span className={styles.next_timeslot_daytext}>
                {locData?.next?.day} {locData?.next?.date}
              </span>
            </div>
            <span className={styles.next_timeslot_time}>
              {locData?.next?.slot}
            </span>
          </div>
        </a>
      </div>
      <DeviceView
        predicted="desktop"
        mobile={
          <FSModal ref={modalRef}>
            <LocationPicker onDone={onDoneAction} />
          </FSModal>
        }
        desktop={
          <PopModal ref={modalRef} bgaction>
            <LocationPicker onDone={onDoneAction} />
          </PopModal>
        }
      />
    </>
  );
}

export default NextDelivery;
