import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import {get, map} from 'lodash';
import NoSSR from '@root/common/NoSSR';
import MegaMenu from '@root/common/MegaMenu';
import LocaleService from '@root/services/locale';
import Layout from '@root/common/Layouts/Desktop';
import NoticeBoard from '@root/common/NoticeBoard';
import Footer from '@root/common/Footers/DesktopMain';
import HeaderDesktop from '@root/common/Headers/DesktopMain';
import EventsService, {LOCATION_CHANGE} from '@root/services/events';

import styles from './styles.module.scss';
import HeroSlider from './components/Hero';
import Rewards from '../components/Rewards';
import QuickLinks from '../components/QuickLinks';
import BannerBlock from './components/BannerBlock';
import ProductSlider from './components/ProductSlider';
import NextDelivery from '../components/NextDelivery';
import FlashSale from '../components/FlashSale';
import BuyAgain from './components/BuyAgain';
import Brands from './components/Brands';
import ImgSlider from './components/ImgSlider';
import BaseService from '@root/services/base';
import DLocationService from '@root/services/deliveryLocation';

function HomeDesktop({screenData}) {
  const {t} = LocaleService.useLocale();
  const bannersData = get(screenData, ['bannersData', 'data'], []);
  const catData = get(screenData, ['catData', 'data', 'results'], []);
  const [blocksData, setBlocksData] = useState(
    get(screenData, ['blocksData', 'data', 'results'], []),
  );

  useEffect(() => {
    let eventSubs = EventsService.$event(LOCATION_CHANGE).subscribe(() => {
      let stored = DLocationService.storedLocation();
      BaseService.getPromoBlocks(
        screenData?.device,
        screenData?.locale,
        stored?.city?.key,
      ).then(result => {
        setBlocksData(result?.data?.results);
      });
    });
    return () => eventSubs.unsubscribe();
  }, []);

  return (
    <Layout
      withTopbar
      header={
        <HeaderDesktop
          nextslot={<NextDelivery />}
          notice={<NoticeBoard screen="home" />}
          menu={<MegaMenu categories={catData} />}
        />
      }
      footer={<Footer categories={catData} />}>
      <div className={styles.home}>
        <div className="br_wrapper br_mb10 br_mt20">
          <HeroSlider banners={bannersData} />
        </div>
        <NoSSR>
          <div className="br_wrapper br_mb30">
            <div className="br_row br_xlarge_p7">
              <div className="br_xlarge_14 br_columns br_mb15">
                <QuickLinks />
              </div>
              <div className="br_xlarge_10 br_columns br_mb15">
                <Rewards />
              </div>
            </div>
          </div>
        </NoSSR>
        <div className="br_wrapper">
          {map(blocksData, (block, index) => {
            switch (block?.type) {
              case 'banners':
                return (
                  <div
                    key={index}
                    className={clsx(styles.home_banner, 'br_mb50')}>
                    <BannerBlock data={block} />
                  </div>
                );
              case 'products':
                return (
                  <div
                    key={index}
                    className={clsx(styles.home_products, 'br_mb50')}>
                    <ProductSlider data={block} />
                  </div>
                );
              case 'flashSale':
                return (
                  <div
                    key={index}
                    className={clsx(styles.home_products, 'br_mb50')}>
                    <FlashSale data={block} />
                  </div>
                );
              case 'brands':
                return (
                  <div
                    key={index}
                    className={clsx(styles.home_products, 'br_mb50')}>
                    <Brands data={block} />
                  </div>
                );
              case 'imgslider':
                return (
                  <div
                    key={index}
                    className={clsx(styles.home_products, 'br_mb50')}>
                    <ImgSlider data={block} />
                  </div>
                );
              case 'myItems':
                return (
                  <div
                    key={index}
                    className={clsx(styles.home_products, 'br_mb50')}>
                    <BuyAgain data={block} />
                  </div>
                );
            }
          })}
        </div>
      </div>
    </Layout>
  );
}

export default HomeDesktop;
