import React from "react";
import clsx from "clsx";
import LocaleService from "@root/services/locale";
import IconBox from "@root/common/IconBox";

import styles from "./styles.module.scss";

function CheckIcon({selectable, selected}) {
  const { t } = LocaleService.useLocale();
  
  return (
    <div className={styles.checkicon}>
      {!selectable && <IconBox className={styles.checkicon_icon} name="map-marker" />}
      {selectable && <IconBox
        name={selected ? "radiobox-marked" : "radiobox-blank"}
        className={clsx(styles.checkicon_radio, selected && styles.selected)}
      />}
    </div>
  );
}

export default CheckIcon;
