import clsx from 'clsx';
import React from 'react'
import { map } from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/router';
import IconBox from '@root/common/IconBox';
import LocaleService from '@root/services/locale';

import styles from './styles.module.scss';

function DeliveryOpts({data}) {
  const router = useRouter();

  return (
    <div className={styles.opts}>
      {map((data?.items || []), (item, index) => {
        let first = index === 0;
        let title = item.title[LocaleService.current];
        return (
          <div key={index} className={clsx(styles.opts_item, first && styles.active)}>
            <Link href={item.link} className={styles.opts_box}>
              <IconBox name={item?.icon} className={styles.opts_icon} />
              <span className={styles.opts_text}>{title}</span>
            </Link>
          </div>
        )
      })}
    </div>
  )
}

export default DeliveryOpts