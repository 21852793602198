import {map, toString} from 'lodash';
import React, {useEffect, useState} from 'react';
import {isValidObj} from '@root/libs/utils';
import BButton from '@root/common/BButton';
import LocaleService from '@root/services/locale';
import AddressMiniature from '@root/common/AddressMiniature';

import styles from './styles.module.scss';

function AddressList({addresses, current, onContinue, onEdit}) {
  const {t} = LocaleService.useLocale();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (!current) return;
    setSelected({id: current?.id ? current.id : current?.address_id});
  }, [current]);

  const isCurrent = toString(selected?.id) === toString(current?.address_id);

  const onSelectAddress = address => {
    setSelected(address);
  };

  const onClickContinue = () => {
    setLoading(true);
    onContinue(selected);
  };

  return (
    <div className={styles.addrlist}>
      {map(addresses, address => {
        return (
          <div key={address?.id} className={styles.addrlist_item}>
            <AddressMiniature
              address={address}
              selected={selected}
              onSelect={onSelectAddress}
              onEdit={() => onEdit(address)}
            />
          </div>
        );
      })}
      <div className={styles.addrlist_button}>
        <BButton
          loading={loading}
          label={t('br_continue')}
          onPress={onClickContinue}
          disabled={!isValidObj(selected) || isCurrent}
        />
      </div>
    </div>
  );
}

export default AddressList;
