import clsx from "clsx";
import Link from "next/link";
import Image from "next/image";
import React, {useRef} from "react";
import IconBox from "@root/common/IconBox";
import Carousel from "@root/common/Carousel";
import { map, startsWith, includes } from "lodash";

import styles from './styles.module.scss';

function HeroSlider({banners}) {
  const carouselRef = useRef(null);
  const carouselProps = {
    autoplay: true,
    loop: true,
    items: 1, 
    margin: 0,
    dots: false,
  };
  
  const linkUrl = (link) => {
    if(includes(link, 'http')) return link;
    else if(startsWith(link, '/')) return link;
    else return `/${link}`;
  }

  const onClickNext = () => {
    carouselRef.current.next();
  };

  const onClickPrev = () => {
    carouselRef.current.prev();
  };

  return (
    <div className={styles.heroslider}>
      <a onClick={onClickPrev} className={clsx(styles.heroslider_nav, styles.left)}><IconBox name='chevron-left' /></a>
      <Carousel id='homehero' ref={carouselRef} attributes={carouselProps}>
        {map(banners, (banner) => {
          return (
            <Link href={linkUrl(banner?.url)} key={banner.id}>
              <span className={styles.heroslider_link}>
                <Image
                  priority
                  width={1180}
                  height={324}
                  alt={banner.caption}
                  src={banner?.image_url}
                />
              </span>
            </Link>
          );
        })}
      </Carousel>
      <a onClick={onClickNext} className={clsx(styles.heroslider_nav, styles.right)}><IconBox name='chevron-right' /></a>
    </div>
  );
}

export default HeroSlider;
