import React, {useState} from 'react';
import Spinner from '@root/common/Spinner';
import IconBox from '@root/common/IconBox';
import {isValidArray} from '@root/libs/utils';
import LocaleService from '@root/services/locale';
import AddressService from '@root/services/address';
import Address from '@root/common/AddressForm';

import AddressList from './components/AddressList';
import styles from './styles.module.scss';

function AddnSelect({loading, current, addresses, onBack, onAddress}) {
  const {t} = LocaleService.useLocale();
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState(null);

  const onPressBack = () => {
    if (isValidArray(addresses) && showForm) setShowForm(false);
    else onBack();
  };

  const onEditAddress = address => {
    let mapData = AddressService.composeMapData(address);
    let addressData = AddressService.composeFormData(address);
    setFormData({mapData, addressData});
    setShowForm(true);
  };

  const onPressAdd = () => {
    setShowForm(true);
    setFormData(null);
  };

  return (
    <div className={styles.addnselect}>
      <div className={styles.addnselect_head}>
        <a onClick={onPressBack} className={styles.addnselect_back}>
          <IconBox
            className={styles.addnselect_add_icon}
            name={LocaleService.isArabic ? 'arrow-right' : 'arrow-left'}
          />
        </a>
        <h4 className={styles.addnselect_heading}>
          {t('checkout_delivery_address')}
        </h4>
        {!showForm ? (
          <a onClick={onPressAdd} className={styles.addnselect_add}>
            <IconBox className={styles.addnselect_add_icon} name="plus" />
            <span className={styles.addnselect_add_text}>
              {t('account_payment_add_new_method')}
            </span>
          </a>
        ) : (
          <span>&nbsp;</span>
        )}
      </div>
      <div className={styles.addnselect_body}>
        {loading ? (
          <div className={styles.addnselect_loading}>
            <Spinner />
          </div>
        ) : showForm || !isValidArray(addresses) ? (
          <Address data={formData} onDone={onAddress} />
        ) : (
          <AddressList
            current={current}
            addresses={addresses}
            onEdit={onEditAddress}
            onContinue={onAddress}
          />
        )}
      </div>
    </div>
  );
}

export default AddnSelect;
