import config from "@root/config";
import LocaleService from "./locale";
import RequestService from "./request";

class Notice {
  constructor() {
    if (!this.instance) this.instance = this;
    return this.instance;
  }

  fetchData(screen) {
    return RequestService.$get(`${config.base_path}_svc/api/md/v1/notice/`, {
      params: {screen, language: LocaleService.current},
    });
  }
}
const NoticeService = new Notice();
export default NoticeService;
