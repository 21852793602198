import clsx from 'clsx';
import Spinner from '@root/common/Spinner';
import BButton from '@root/common/BButton';
import {includes, map, toLower} from 'lodash';
import FormRadio from '@root/common/FormRadio';
import React, {useEffect, useState} from 'react';
import LocaleService from '@root/services/locale';
import ExpressDelivery from '@root/common/ExpressDelivery';
import DLocationService from '@root/services/deliveryLocation';

import styles from './styles.module.scss';

function Locations({onDone}) {
  const {t} = LocaleService.useLocale();
  const [regions, setRegions] = useState({});
  const [cities, setCities] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [process, setProcess] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const {locData, locRegion, locCity} = DLocationService.useLocationData();

  useEffect(() => {
    if (!locData) return;
    setSelectedCity(locCity?.key);
    setSelectedRegion(locRegion?.key);

    setCities(locRegion?.city);
    setRegions(locData?.regions?.data);
  }, [locData]);

  const onChangeSearch = event => {
    setSearch(event.target.value);
  };

  const onClickRegion = region => {
    setLoading(true);
    setSelectedRegion(region?.key);
    DLocationService.fetchLocations({regionId: region?.key}).finally(() =>
      setLoading(false),
    );
  };

  const onSelectCity = city => {
    setSelectedCity(city?.key);
  };

  const onClickAction = () => {
    setProcess(true);
    DLocationService.fetchLocations({
      regionId: selectedRegion,
      cityId: selectedCity,
    })
      .then(onDone)
      .finally(() => setProcess(false));
  };

  return (
    <div className={styles.locpicker}>
      <div className={styles.locpicker_head}>
        <h4 className={styles.locpicker_heading}>{t('select_location')}</h4>
      </div>

      <div className={styles.regions}>
        {map(regions, region => {
          let isActive = selectedRegion === region.key;
          return (
            <a
              key={region.key}
              onClick={() => onClickRegion(region)}
              className={clsx(styles.regions_link, isActive && styles.active)}>
              {region?.label}
            </a>
          );
        })}
      </div>

      <div className={styles.search}>
        <input
          type="text"
          onChange={onChangeSearch}
          placeholder={`Search sub areas`}
          className={styles.search_input}
        />
      </div>

      <div className={styles.cities}>
        {loading ? (
          <div className={styles.loading}>
            <Spinner size={24} />
            <span>Loading...</span>
          </div>
        ) : cities?.length === 0 ? (
          <div className={styles.cities_empty}>
            <span className={styles.cities_empty_test}>
              Please Select an emirate
            </span>
          </div>
        ) : (
          map(cities, city => {
            let isSelected = city?.key === selectedCity;
            if (!includes(toLower(city?.label), toLower(search))) return;
            else
              return (
                <div key={city?.key} className={styles.cities_item}>
                  <FormRadio
                    label={city?.label}
                    selected={isSelected}
                    onChange={() => onSelectCity(city)}
                  />
                  {city?.express_delivery_available && (
                    <ExpressDelivery size={7} />
                  )}
                </div>
              );
          })
        )}
      </div>

      <div className={styles.locpicker_action}>
        <BButton
          loading={process}
          label={t('br_done')}
          disabled={!selectedCity}
          onPress={onClickAction}
          className={styles.locpicker_button}
        />
      </div>
    </div>
  );
}

export default Locations;
