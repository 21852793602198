import {isFunction, isUndefined} from 'lodash';
import React, {useRef, forwardRef, useImperativeHandle, useEffect} from 'react';

import styles from './styles.module.scss';

function Carousel(props, ref) {
  const owlRef = useRef(null);

  useEffect(() => {
    if (isUndefined(window.jQuery)) return;
    if(props.children?.length === 0) return;

    owlRef.current = jQuery(`#${props.id}`);
    if (isFunction(owlRef.current.owlCarousel)) {
      owlRef.current.owlCarousel(props.attributes);
    }
  }, [props]);

  useImperativeHandle(ref, () => ({
    next() {
      owlRef.current.trigger('next.owl.carousel');
    },
    prev() {
      owlRef.current.trigger('prev.owl.carousel');
    },
    to(index) {
      owlRef.current.trigger('to.owl.carousel', index)
    }
  }));

  return (
    <div id={props.id} className="owl-carousel">
      {props.children}
    </div>
  );
}

export default forwardRef(Carousel);
