import React, { useState } from "react";
import { get, find, hasIn } from "lodash";
import AddressService from "@root/services/address";
import LocaleService from "@root/services/locale";

import styles from "./styles.module.scss";
import clsx from "clsx";

function MapDetails({ address }) {
  const { t } = LocaleService.useLocale();
  const [showDetail, setShowDetail] = useState(false);
  const custom = get(address, ["custom_attributes"], []);
  const mapaddressObj = find(custom, { attribute_code: "additional_address" });
  const mapaddress = get(mapaddressObj, ["value"], "");
  const parsed = AddressService.parseAddress(mapaddress);

  const onPressDetail = () => {
    setShowDetail(!showDetail);
  };

  if (mapaddress === "") return null;

  return (
    <div className={styles.mapdetails}>
      <span onClick={onPressDetail} className={styles.mapdetails_toggle}>
        {t('account_address_details')}
      </span>
      <div
        className={clsx(styles.mapdetails_content, showDetail && styles.active)}
      >
        {hasIn(parsed, ["premise"]) && (
          <span className={styles.mapdetails_item}>
            <strong>{t('account_address_map_premise')}: </strong> {get(parsed, ["premise"], "")}
          </span>
        )}
        {hasIn(parsed, ["area"]) && (
          <span className={styles.mapdetails_item}>
            <strong>{t('account_address_map_area')}: </strong> {get(parsed, ["area"], "")}
          </span>
        )}
        {hasIn(parsed, ["city"]) && (
          <span className={styles.mapdetails_item}>
            <strong>{t('br_city')}: </strong> {get(parsed, ["city"], "")}
          </span>
        )}
        {hasIn(parsed, ["country"]) && (
          <span className={styles.mapdetails_item}>
            <strong>{t('br_country')}: </strong> {get(parsed, ["country"], "")}
          </span>
        )}
      </div>
    </div>
  );
}

export default MapDetails;
