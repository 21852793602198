import clsx from 'clsx';
import Link from 'next/link';
import {filter, map} from 'lodash';
import React, {useRef} from 'react';
import IconBox from '@root/common/IconBox';
import Carousel from '@root/common/Carousel';
import LocaleService from '@root/services/locale';

import styles from './styles.module.scss';

function Brands({data}) {
  const carouselRef = useRef(null);
  const lang = LocaleService.current;
  const filtered = filter(data?.list, item => item?.active);

  const carouselProps = {
    items: 6,
    margin: 0,
    dots: false,
    rtl: LocaleService.isArabic,
    responsive: {
      0: {items: 2},
      768: {items: 5},
      990: {items: 6},
      1200: {items: 7},
    },
  };

  const onClickNext = () => {
    carouselRef.current.next();
  };

  const onClickPrev = () => {
    carouselRef.current.prev();
  };

  return (
    <div className={styles.brands}>
      <div className={styles.brands_head}>
        <h4 className={styles.brands_heading}>{data?.heading[lang]}</h4>
      </div>
      <div className={styles.brands_scroll}>
        <a
          onClick={onClickPrev}
          className={clsx(
            styles.brands_nav,
            LocaleService.isArabic ? styles.right : styles.left,
          )}>
          <IconBox
            name={LocaleService.isArabic ? 'chevron-right' : 'chevron-left'}
          />
        </a>
        <Carousel id={'buyagain'} ref={carouselRef} attributes={carouselProps}>
          {map(filtered, (item, index) => {
            return (
              <Link key={index} href={`${item?.link}`}>
                <div className={styles.brand_link}>
                  <img
                    alt=""
                    src={item?.image?.[lang]}
                    className={styles.brand_image}
                  />
                </div>
              </Link>
            );
          })}
        </Carousel>
        <a
          onClick={onClickNext}
          className={clsx(
            styles.brands_nav,
            LocaleService.isArabic ? styles.left : styles.right,
          )}>
          <IconBox
            name={LocaleService.isArabic ? 'chevron-left' : 'chevron-right'}
          />
        </a>
      </div>
    </div>
  );
}

export default Brands;
