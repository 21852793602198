import clsx from "clsx";
import React from "react";
import Link from "next/link";
import { get, map } from "lodash";
import LocaleService from "@root/services/locale";

import styles from "./styles.module.scss";

function BannerBlock({ data }) {
  const { t } = LocaleService.useLocale();
  const images = get(data, ["content"], []);

  const isPair = (images.length > 1);

  return (
    <div className={styles.banner}>
      {map(images, (image, index) => {
        if(!image?.content) return;
        return (
          <Link key={index} href={`/${image?.url}`}>
            <span className={clsx(styles.banner_image, isPair && styles.banner_pair)}>
              <img alt="" src={image?.content} />
            </span>
          </Link>
        );
      })}
    </div>
  );
}

export default BannerBlock;
