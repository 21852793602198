import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import config from '@root/config';
import LocaleService from '@root/services/locale';
import {map, take, includes, startsWith} from 'lodash';

import styles from './styles.module.scss';

function CatGrid({categories}) {
  const {t} = LocaleService.useLocale();
  const catList = take(categories, config.hs_category_count);

  const linkUrl = link => {
    if (includes(link, 'http')) return link;
    else if (startsWith(link, '/')) return link;
    else return `/${link}`;
  };

  return (
    <div className={styles.catgrid}>
      <div className={styles.catgrid_head}>
        <h2 className={styles.catgrid_heading}>
          {t('home_shop_by_category')}
        </h2>
        <span className={styles.catgrid_headlink}>
          <Link href="/categories">{t('br_show_all')}</Link>
        </span>
      </div>
      <div className={styles.catgrid_box}>
        <ul className={styles.catgrid_list}>
          {map(catList, item => {
            return (
              <li key={item.id} className={styles.catgrid_item}>
                <Link href={linkUrl(item?.request_path)}>
                  <span className={styles.catgrid_link}>
                    <span className={styles.catgrid_image}>
                      <Image
                        alt="category"
                        width={200}
                        height={200}
                        src={item.image}
                      />
                    </span>
                    <span className={styles.catgrid_title}>{item.name}</span>
                  </span>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default CatGrid;
