import React from "react";
import { isNull } from 'lodash';
import LocaleService from "@root/services/locale";
import IconBox from "@root/common/IconBox";

import styles from './styles.module.scss';

function Actions({isDefault, onEdit, onDelete}) {
  const { t } = LocaleService.useLocale();

  return <div className={styles.actions}>
    {!isNull(onEdit) && <a onClick={onEdit} className={styles.actions_button}>
      <IconBox name='pencil-outline' /> 
    </a>}
    {(!isDefault && !isNull(onDelete)) && <a onClick={onDelete} className={styles.actions_button}>
      <IconBox name='delete-outline' /> 
    </a>}
  </div>;
}

export default Actions;
